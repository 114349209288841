@import '../../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: 96px;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1550px;
  margin: 0 auto;
  @media (max-width: 1450px) {
    height: 72px;
  }
}

.menuActiveLink {
  color: #3d70a1 !important;
  position: relative;
  &:after {
    position: absolute;
    content: '';
    height: 1px;
    width: 60%;
    bottom: 20px;
    right: 0;
    left: 0;
    margin: 0 auto;
    background: var(--Web-Primary, #3d70a1);
    @media (max-width: 1450px) {
      bottom: 14px;
    }
    @media (max-width: 1023px) {
      content: unset;
    }
  }
  & span {
    font-family: Nunito;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #3d70a1;
  }
}
/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: unset;
  }
}
.logoLink:hover {
  /* border-bottom: 4px solid var(--marketplaceColor);
  border-radius: 0; */
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}
.navWrapperAfterLogin {
  & .root {
    max-width: 100%;
  }
  & .menuWrapper {
    background-image: url('../../../../assets/menu1.png');
    background-size: 100% 96px;
    min-width: 724px;
    margin-right: 57px;
    padding-right: 64px;
    height: 96px;
    @media (max-width: 1450px) {
      background-size: 100% 72px;
      padding-right: 32px;
      height: 72px;
    }
    @media (max-width: 1450px) {
      margin-right: 16px;
    }
    @media (max-width: 1450px) {
      min-width: 600px;
    }
  }
  & .topWhiteBlack {
    height: 16px;
    width: 100%;
    background: #fff;
    @media (max-width: 1450px) {
      height: 4px;
    }
  }
  & .logoLink {
    background-image: url('../../../../assets/menu2.png');
    background-size: 100% 96px;
    padding: 0 124px 0 79px;
    @media (max-width: 1450px) {
      background-size: 100% 72px;
    }
    & img {
      width: 240px;
      height: 56px;
    }
  }
  & .profileMenuLabel {
  }
}
.menuWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 66px;
  @media (max-width: 1450px) {
    padding-right: 36px;
  }
  & :global(.NamedLink_active) {
    color: #3d70a1;
    position: relative;
    & span {
      color: #3d70a1;
      font-weight: 700;
      &:after {
        position: absolute;
        content: '';
        height: 1px;
        width: 60%;
        bottom: 20px;
        right: 0;
        left: 0;
        margin: 0 auto;
        background: var(--Web-Primary, #3d70a1);
        @media (max-width: 1450px) {
          bottom: 14px;
        }
        @media (max-width: 1023px) {
          content: unset;
        }
      }
    }
  }
}
.menuLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}
.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 40.5px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: 96px;
  @media (max-width: 1450px) {
    height: 72px;
  }
  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  transition: var(--transitionStyleButton);

  color: var(--Black-Shade-Para_Text, #949494);
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--Web-Primary, #3d70a1);
    font-weight: 700;
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.topbarDesktopLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 40.5px 0;
  text-decoration: inherit;
  @media (max-width: 1450px) {
    margin: 28px 0;
  }
}

.createListing {
  composes: topbarDesktopLabel;
}

/* Inbox */
.inboxLink {
  transition: var(--transitionStyleButton);

  color: var(--Black-Shade-Para_Text, #949494);
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--Web-Primary, #3d70a1);
    font-weight: 700;
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.inbox {
  composes: topbarDesktopLabel;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;
  &:hover {
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}
.menuTopContent {
  background: #3d70a1;
  border-radius: 15px;
  padding: 20px 16px 10px;
  margin-bottom: 24px;
  & .nameText {
    font-family: Nunito;
    font-size: 18px;
    font-weight: 500;
    line-height: 24.55px;
    text-align: left;
    color: #fff;
    text-align: center;
    text-transform: capitalize;
    margin: 12px 0 0;
    padding: 0;
  }
  & .avatarTopContent {
    margin: 0 auto;
    width: 70px;
    height: 70px;
    & img {
      border: 2px solid #ffffff;
      box-shadow: 0px 10px 35px 0px #0000001f;
      border-radius: 100px;
      background-color: #fff;
    }
  }
}
.profileMenuContent {
  min-width: 276px !important;
  /* padding-top: 20px; */
  right: 10px !important;
  border-radius: 15px;
  &>div:nth-child(1){
    right: 20px !important;
  }
}

/* Authentication */
.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  margin: 0 16px 0 16px;

  border-radius: 5px;
  background: var(--Web-Primary, #3d70a1);

  color: var(--Black-Shade-White, #fff) !important;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 44px;
  width: 95px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  & span {
    color: var(--Black-Shade-White, #fff) !important;
  }
  &:hover {
    text-decoration: none;
    background: var(--Web-Primary, #3d71a1e1);
  }
  & span {
    &:after {
      content: unset !important;
    }
  }
}
.navLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0 16px 0 16px;

  color: var(--Black-Shade-Para_Text, #949494);
  font-family: Nunito;
  font-size: 1.5rem;
  font-style: normal;
  /* font-weight: 400; */
  line-height: normal;
  height: 100%;
  display: block;
  @media (max-width: 1450px) {
    padding: 0 12px 0 12px;
  }
  &:hover {
    color: var(--Web-Primary, #3d70a1);
    font-weight: 700;
    border-radius: 0;
    text-decoration: none;
  }
}

.loginLink {
  border-left: 1px solid #949494;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 18px;
  padding: 0 16px 0 32px;
  margin-left: 16px;
  color: var(--Black-Shade-Text_Black, #272727);
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--colorBlack);
    border-radius: 0;
    text-decoration: none;
    font-weight: 700;
  }
  &:after {
    bottom: -17px;
    right: -20px;
  }
  & span {
    &:after {
      content: unset !important;
    }
  }
  & span {
    margin: 0;
  }
}

.linkText,
.login {
  composes: textSmall from global;

  display: inline-block;
  margin: 40.5px 0;
  text-decoration: inherit;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #949494;
  @media (max-width: 1450px) {
    margin: 28px 0;
  }
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  composes: textSmall from global;
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;

  /* Dimensions */
  width: 100%;
  margin: 0 0 16px;
  padding: 0px 24px;

  /* Layout details */
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.55px;
  text-align: left;
  color: #949494;

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }
  & span:after {
    content: unset !important;
  }
  & svg{
    fill: transparent;
  }
}

.logoutButton {
  display: flex;
  gap: 12px;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.55px;
  text-align: left;
  

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: #949494;
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-bottom: 0;
  }
  & svg{
    fill: transparent;
  }
}

.currentPage {
  color: #3D70A1;
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}
