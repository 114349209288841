@import '../../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
}
.menuActiveLink {
  color: #3d70a1 !important;
}
.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  font-weight: var(--fontWeightBlack);
  font-size: 24px;
  line-height: 24px;

  padding: 3px 0 3px 0;
  margin-bottom: 1px;
  margin-top: 16px;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 56px;
    padding: 5px 0 3px 0;
    /* x * 8px */
    margin-top: 24px;
  }
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--colorGrey300);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    margin: 0 0 36px 0;
  }
}

.customLinkFont {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 21px;
  line-height: 24px;

  padding: 4px 0 2px 0;
  /* x * 6px */
  margin-top: 24px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 40px;
    padding: 1px 0 7px 0;
  }
}

.inbox {
  /* Font */
  composes: customLinkFont;
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
  color: var(--Black-Shade-Para_Text, #949494);
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.navigationLink {
  /* Font */
  composes: customLinkFont;

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
  color: var(--Black-Shade-Para_Text, #949494);
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonPrimary from global;
  border-radius: 5px;
  background: var(--Web-Primary, #3d70a1);
  color: var(--Black-Shade-White, #fff);
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;
 line-height: 30px;
  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  /* color: var(--colorBlack);
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px; */
}

.spacer {
  width: 100%;
  height: 124px;
}
